@import '../../../shared/styles/variable';

.section-col {
  background-color: #fff;
  border-radius: 16px;
  padding: 20px;
  & .ant-form-item {
    margin-bottom: 16px;
  }
}

.video-call-card {
  margin-bottom: 32px;

  & .ant-card-cover {
    & img {
      height: 140px;
      object-fit: cover;
    }
  }

  & .ant-card-body {
    padding-top: 0;
  }
}

.video-call {
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;

  & h5 {
    margin-bottom: 4px;
    font-size: @font-size-base;
  }

  & p {
    margin-bottom: 20px;
    color: @text-color;
  }
}

.video-call-avatar-view {
  display: flex;
  align-items: center;
  margin-bottom: 12px;

  @media screen and (max-width: @screen-xl) {
    margin-bottom: 16px;
  }
}

.video-call-avatar {
  width: 28px;
  height: 28px;
  border: @border-style-base 2px @white;
  margin-right: -4px;

  [dir=rtl] & {
    margin-right: 0;
    margin-left: -4px;
  }
}

.video-call-avatar-count {
  border: 2px @border-style-base @text-color-white;
  width: 28px;
  height: 28px;
  background-color: @success-color;
  color: @text-color-white;
  border-radius: @border-radius-circle;
  margin-right: -4px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  position: relative;
  font-size: 12px;
  font-weight: @font-weight-bold;

  [dir=rtl] & {
    margin-right: 0;
    margin-left: -4px;
  }
}

.video-call-btn-view {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  margin-left: -8px;
  margin-right: -8px;
  margin-top: 5px;

  & .ant-btn {
    margin: 0 8px 8px;
    text-transform: capitalize;
  }
}

.video-call-btn {
  background-color: lighten(@primary-color, 48);
  color: @primary-color;
  border: 0 none;

  &:hover, &:focus {
    background-color: lighten(@primary-color, 50);
    color: @primary-color;
  }
}

.video-call-user-info {
  display: flex;
  text-align: center;
  color: @text-color-secondary;

  @media screen and (max-width: @screen-xl) {
    margin-bottom: 30px;
  }

  & h3 {
    font-size: @font-size-lg;
    font-weight: @font-weight-medium;
    margin-bottom: 4px;
  }

  & p {
    margin-bottom: 0;
  }

  & .video-call-user-details {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
  }
}

.video-call-user {
  position: relative;
  margin-right: 15px;
  & .ant-avatar {
    border: @border-style-base 5px @body-background;
    width: 84px;
    height: 84px;
  }
}

.video-call-user-status {
  position: absolute;
  right: 2px;
  bottom: 12px;
  z-index: 1;
  width: 30px;
  height: 30px;
  background-color: @primary-color;
  color: @text-color-white;
  border-radius: @border-radius-circle;
  border: @border-style-base 3px @text-color-white;
  display: flex;
  justify-content: center;
  align-items: center;

  & .MuiSvgIcon-root {
    font-size: 14px;
  }

  [dir=rtl] & {
    right: auto;
    left: 2px;
  }
}

@primary-color: #34ba27;