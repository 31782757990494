@import '../../shared/styles/variable';

.signup-right {
  background-image: url("auth-background.png");
  background-repeat:no-repeat;
  background-position: center center;
  background-color: white;
  justify-content: flex-end;
  & .auth-wel-content {
    width: 100%;
    max-width: none;
    & h2 {
      color: #0059ee;
    }
    & p {
      color: #6b7280;
    }
  }
}

.signin-right {
  background-image: url("signin-background.png");
  background-repeat:no-repeat;
  background-position: center 20%;
  background-color: white;
  justify-content: flex-end;
  & .auth-wel-content {
    width: 100%;
    max-width: none;
    & h2 {
      color: #3ab12f;
    }
    & p {
      color: #6b7280;
    }
  }
}

@primary-color: #34ba27;