@import '../../../../../shared/styles/variable';
@import '../../../../../shared/styles/mixin';

.user-trading-group-label {
  display: flex;
  width: 100%;
  align-items: center;
}

.user-trading-group-popover {
  display: inline-flex;
  width: 14px;
  height: 14px;
  overflow: hidden;
}

.user-trading-group-prelabel {
  display: inline-flex;
  margin-right: 5px;
}
@primary-color: #34ba27;