@import '../../../shared/styles/variable';
@import '../../../shared/styles/mixin';

[data-simplebar] {
  position: relative;
  width: 100%;
  height: 100%;

  & .simplebar-offset,
  & .simplebar-content-wrapper,
  & .simplebar-content {
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1;
  }
}

.main-support {
  padding-left: 15px;
  padding-right: 15px;
  text-align: right;
  margin-top: 10px;
  margin-bottom: 10px;
}
@primary-color: #34ba27;