@import '../../../shared/styles/variable';

.select-box {
  margin-left: 8px;
  cursor: pointer;
  font-size: @font-size-base;

  [dir=rtl] & {
    margin-left: 0;
    margin-right: 8px;
  }

  @media screen and (min-width: @screen-xxl) {
    margin-left: 24px;

    [dir=rtl] & {
      margin-left: 0;
      margin-right: 24px;
    }
  }

  &.ant-select:not(.ant-select-customize-input) .ant-select-selector {
    height: 30px;
  }

  &.ant-select-single:not(.ant-select-customize-input) .ant-select-selector .ant-select-selection-search-input {
    height: 28px;
  }

  &.ant-select-single .ant-select-selector .ant-select-selection-item, .ant-select-single .ant-select-selector .ant-select-selection-placeholder {
    line-height: 28px;
  }
}

.select-option {
  cursor: pointer;
  padding: 8px;
  font-size: @font-size-base;
}
@primary-color: #34ba27;