@import '../../../../../shared/styles/variable';
@import '../../../../../shared/styles/mixin';

.nav-menu-style {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  margin-right: -10px;
  margin-left: -10px;
}

.nav-menu-style-item {
  padding-left: 10px;
  padding-right: 10px;
  margin-bottom: 20px;
}

.nav-menu-style-item-cur {
  position: relative;
  cursor: pointer;
}
@primary-color: #34ba27;