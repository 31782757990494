@import '../../../shared/styles/variable';

.langBtn {
  display: flex;
  align-items: center;
  width: 100%;
  font-size: @font-size-lg;
  border-radius: 0;
  padding: 2px 12px 18px 12px;
  color: @text-color;
  text-transform: capitalize;

  &:hover, &:focus {
    color: @text-color;
    background-color: transparent;
  }

  @media screen and (min-width: @screen-md) {
    font-weight: @font-weight-medium;
    text-transform: uppercase;
    margin-top: 0;
    width: 40px;
    height: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    background-color: @body-background;
    color: @text-color-secondary;
    padding: 9px;
    border-radius: @border-radius-circle;
    border: @border-style-base @border-width-base transparent;
    margin-left: 7.4px;
    margin-right: 7.4px;
    line-height: 1;

    &:hover, &:focus {
      color: @text-color;
      background-color: lighten(@body-background, 0.5);
      border-color: darken(@body-background, 10);
    }
  }
}

.lang-text {
  font-size: @font-size-lg;
  font-weight: @font-weight-regular;
  display: inline-block;

  @media screen and (min-width: @screen-md) {
    display: none;
  }
}

.lang-icon {
  position: relative;
  display: none;

  @media screen and (min-width: @screen-md) {
    display: block;
  }
}

.langItem {
  width: 160px;
  display: flex;
  flex-direction: row;
  align-items: center;

  & h4 {
    margin-left: 16px;
    margin-bottom: 0;
    font-weight: @font-weight-medium;
    font-size: @font-size-base;

    [dir=rtl] & {
      margin-left: 0;
      margin-right: 16px;
    }

    @media screen and (min-width: @screen-xl) {
      font-size: @font-size-lg;
    }
  }
}
@primary-color: #34ba27;