@import '../../../../shared/styles/variable';

.orderSummaryItem {
  font-size: 14px;
  font-weight: 500;
  color: rgb(107, 114, 128);
  display: flex;
  -moz-box-pack: justify;
  justify-content: space-between;
  margin-top: 8px;
  margin-bottom: 16px;

  & p {
    margin-bottom: 0;
    color: rgb(107, 114, 128);
  }

  & span {
    color: rgb(17, 24, 39);
  }
}

.unpaid-label {
  color: rgb(248, 78, 78) !important;
  background-color: rgba(248, 78, 78, 0.267);
  padding: 3px 10px;
  border-radius: 16px;
  display: inline-block;
}

.paid-label {
  color: rgb(67, 200, 136) !important;
  background-color: rgba(67, 200, 136, 0.267);
  padding: 3px 10px;
  border-radius: 16px;
  display: inline-block;
}
@primary-color: #34ba27;